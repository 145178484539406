<template>
  <base-nav
    class="navbar-top navbar-dark"
    id="navbar-main"
    :show-toggle-button="false"
    expand
  >
    <SwitchLanguage />
    <ul class="navbar-nav align-items-center d-none d-md-flex ml-lg-auto">
      <li class="nav-item dropdown">
        <base-dropdown class="nav-link pr-0">
          <template v-slot:title>
            <div class="media align-items-center">
              <span class="avatar avatar-sm rounded-circle">
                <img alt="Image placeholder" :src="get_member_image" />
              </span>
              <div class="media-body ml-2 d-none d-lg-block">
                <span class="mb-0 text-sm font-weight-bold">
                  {{ get_member_name }}
                </span>
              </div>
            </div>
          </template>
          <div class="dropdown-header noti-title">
            <h6 class="text-overflow m-0">{{ $t("sidebar.welcome") }}!</h6>
          </div>
          <router-link to="/update-profile" class="dropdown-item">
            <i class="ni ni-single-02"></i>
            <span>{{ $t("sidebar.update profile") }}</span>
          </router-link>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item" @click.prevent="logout">
            <i class="ni ni-user-run"></i>
            <span>{{ $t("login.logout") }}</span>
          </a>
        </base-dropdown>
      </li>
    </ul>
  </base-nav>
</template>
<script>
import SwitchLanguage from "@/components/SwitchLanguage.vue";
export default {
  components: {
    SwitchLanguage,
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchQuery: "",
    };
  },
  computed: {
    get_member_name() {
      return this.$store.getters.userName;
    },
    get_member_image() {
      return this.$store.getters.userImage;
    },
  },
  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    logout() {
      this.$store.dispatch("Logout").then((path) => {
        this.$router.push(path);
      });
    },
  },
};
</script>
