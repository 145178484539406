<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }" v-cloak>
    <side-bar
      :background-color="sidebarBackground"
      short-title="Argon"
      title="Argon"
    >
      <template v-slot:links>
        <sidebar-item
          :link="{
            name: $t('sidebar.dashboard'),
            icon: 'ni ni-chart-pie-35 text-primary',
            path: '/dashboard',
          }"
        />
        <sidebar-item
          v-if="user_role == 'admin' || user_role == 'social_media_specialist'"
          v-can="'domainList'"
          :link="{
            name: $t('sidebar.domains'),
            icon: 'ni fas fa-chart-area text-primary',
            path: '/domains',
          }"
        />
        <sidebar-item
          v-if="user_role == 'admin'"
          v-can="'departmentList'"
          :link="{
            name: $t('sidebar.departments'),
            icon: 'ni fas fa-th-large text-primary',
            path: '/departments',
          }"
        />
        <sidebar-item
          v-if="user_role == 'admin' || user_role == 'social_media_specialist'"
          v-can="'departmentList'"
          :link="{
            name: $t('sidebar.all departments'),
            icon: 'ni fas fa-th-large text-primary',
            path: '/all-departments',
          }"
        />
        <sidebar-item
          v-if="user_role == 'admin'"
          :link="{
            name: $t('sidebar.languages'),
            icon: 'ni fas fa-globe-africa text-primary',
            path: '/languages',
          }"
        />
        <sidebar-item
          v-if="user_role == 'admin' || user_role == 'projects_manager'"
          v-can="'userList'"
          :link="{
            name: $t('sidebar.users'),
            icon: 'ni fas fa-users text-primary',
            path: '/users',
          }"
        />
        <sidebar-item
          v-if="user_role == 'admin' || user_role == 'projects_manager'"
          :link="{
            name: $t('sidebar.contact'),
            icon: 'ni fas fa-globe-africa text-primary',
            path: '/contacts',
          }"
        />
        <sidebar-item
          v-if="user_role == 'admin' || user_role == 'social_media_specialist'"
          :link="{
            name: $t('sidebar.newsletters'),
            icon: 'ni far fa-envelope text-primary',
            path: '/newsletter',
          }"
        />
        <sidebar-item
          v-if="user_role == 'admin' || user_role == 'social_media_specialist'"
          :link="{
            name: $t('sidebar.ads'),
            icon: 'ni fas fa-ad text-primary',
            path: '/ads',
          }"
        />
        <!-- <sidebar-item
          :link="{
            name: $t('sidebar.adables'),
            icon: 'ni ni-tv-2 text-primary',
            path: '/assigned-ads',
          }"
        /> -->
        <sidebar-item
          v-if="user_role == 'admin' || user_role == 'projects_manager'"
          :link="{
            name: $t('sidebar.careers'),
            icon: 'ni ni-tv-2 text-primary',
            path: '/careers',
          }"
        />
        <sidebar-item
          v-if="user_role == 'admin' || user_role == 'projects_manager'"
          :link="{
            name: $t('sidebar.quizzes'),
            icon: 'ni ni-tv-2 text-primary',
            path: '/quizzes',
          }"
        />
        <sidebar-item
          v-if="
            user_role != 'editor' &&
            user_role != 'business_developer' &&
            user_role != 'projects_manager'
          "
          v-can="'articleList'"
          :link="{
            name: $t('sidebar.all articles'),
            icon: 'ni fas fa-newspaper text-primary',
            path: '/articles',
          }"
        />
        <!-- <sidebar-item
          v-can="'articleList'"
          :link="{
            name: $t('sidebar.articles'),
            icon: 'ni fas fa-newspaper text-primary',
            path: '/articles',
          }"
        /> -->
        <sidebar-item
          v-can="'articleCreate'"
          :link="{
            name: $t('table.add article'),
            icon: 'ni fas fa-newspaper text-primary',
            path: '/article-form',
          }"
        />
        <sidebar-item
          v-can="'articleCreate'"
          :link="{
            name: $t('sidebar.draft articles'),
            icon: 'ni fas fa-newspaper text-primary',
            path: '/articles/draft',
          }"
        />
        <sidebar-item
          v-if="user_role != 'editor'"
          v-can="'articleCreate'"
          :link="{
            name: $t('sidebar.new articles'),
            icon: 'ni fas fa-newspaper text-primary',
            path: '/articles/new',
          }"
        />
        <sidebar-item
          v-if="user_role != 'editor'"
          v-can="'articleCreate'"
          :link="{
            name: $t('sidebar.after editing articles'),
            icon: 'ni fas fa-newspaper text-primary',
            path: '/articles/after-editing',
          }"
        />
        <sidebar-item
          v-if="user_role != 'editor'"
          v-can="'articleCreate'"
          :link="{
            name: $t('sidebar.under review articles'),
            icon: 'ni fas fa-newspaper text-primary',
            path: '/articles/under-review',
          }"
        />
        <sidebar-item
          v-can="'articleCreate'"
          :link="{
            name: $t('sidebar.for editing articles'),
            icon: 'ni fas fa-newspaper text-primary',
            path: '/articles/under-editing',
          }"
        />
        <!-- <sidebar-item
          v-can="'articleCreate'"
          :link="{
            name: $t('sidebar.under review'),
            icon: 'ni fas fa-newspaper text-primary',
            path: '/articles/under-review',
          }"
        /> -->
        <sidebar-item
          v-if="user_role != 'editor' && user_role != 'reviewer'"
          v-can="'articleCreate'"
          :link="{
            name: $t('sidebar.ready for publish'),
            icon: 'ni fas fa-newspaper text-primary',
            path: '/articles/ready-for-publish',
          }"
        />
        <sidebar-item
          v-if="user_role != 'editor' && user_role != 'reviewer'"
          v-can="'articleCreate'"
          :link="{
            name: $t('sidebar.scheduled'),
            icon: 'ni fas fa-newspaper text-primary',
            path: '/articles/schedule',
          }"
        />
        <sidebar-item
          v-can="'articleCreate'"
          :link="{
            name: $t('sidebar.published'),
            icon: 'ni fas fa-newspaper text-primary',
            path: '/articles/publish',
          }"
        />
        <sidebar-item
          v-if="user_role != 'editor'"
          v-can="'articleCreate'"
          :link="{
            name: $t('sidebar.cross media'),
            icon: 'ni fas fa-chart-area text-primary',
            path: '/cross-media',
          }"
        />
        <sidebar-item
          v-can="'articleCreate'"
          :link="{
            name: $t('sidebar.tags'),
            icon: 'ni fas fa-hashtag text-primary',
            path: '/tags',
          }"
        />
        <sidebar-item
          v-can="'articleCreate'"
          :link="{
            name: $t('sidebar.keywords'),
            icon: 'ni fas fa-hashtag text-primary',
            path: '/keywords',
          }"
        />
        <sidebar-item
          v-if="
            user_role == 'admin' ||
            user_role == 'projects_manager' ||
            user_role == 'business_developer' ||
            user_role == 'manager'
          "
          :link="{
            name: $t('sidebar.reports'),
            icon: 'ni fas fa-hashtag text-primary',
            path: '/reports',
          }"
        />
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <!-- your content here -->
        <router-view></router-view>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
export default {
  components: {
    DashboardNavbar,
    ContentFooter,
  },
  data() {
    return {
      user_role: "",
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
    };
  },
  created() {
    this.user_role = JSON.parse(localStorage.getItem("userData"))["role"].name;
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
<style lang="scss"></style>
