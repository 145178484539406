import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import ArgonDashboard from "./plugins/argon-dashboard";
import "element-plus/lib/theme-chalk/index.css";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import { createI18n } from "vue-i18n";
import en from "./locales/en.json";
import ar from "./locales/ar.json";
let userLang = "ar";
if (localStorage.getItem("userLang")) {
  userLang = localStorage.getItem("userLang");
} else {
  userLang = "ar";
}
if (userLang == "ar") {
  require("./assets/scss/style-rtl.css");
} else {
  //
}
const language = {
  en: en,
  ar: ar,
};
const i18n = createI18n({
  locale: userLang,
  messages: language,
});

if (process.env.NODE_ENV == "development") {
  // Development
  axios.defaults.baseURL = "https://bashkatibnews.com/api/v1/";
  axios.defaults.headers["api-key"] =
    "8cd8d8989c6bcab8aB7fsad361602bc6a5094a9m";
  console.log("Development Version");
} else {
  // Development
  if (window.location.host == "admin.bashkatibnews.com") {
    axios.defaults.baseURL = "https://bashkatibnews.com/api/v1/";
    axios.defaults.headers["api-key"] =
      "8cd8d8989c6bcab8aB7fsad361602bc6a5094a9m";
  }
}
axios.defaults.headers["X-Requested-With"] = "XMLHttpRequest";
// Get Token from Store OR Localstorage
const userToken = store.getters.isToken
  ? store.getters.isToken
  : localStorage.getItem("token");

const appInstance = createApp(App);
appInstance.use(router);
appInstance.use(store);
appInstance.use(ArgonDashboard);
appInstance.use(VueSweetalert2);
appInstance.use(i18n);
// appInstance.use(CKEditor);
appInstance.mount("#app");

if (userToken) {
  // Update User Data after refresh
  let userData = JSON.parse(localStorage.getItem("userData"));
  store.commit("setLoginData", userData);
  // Axios Headers
  axios.defaults.headers.common.Authorization = `bearer ${userToken}`;
  axios.defaults.headers["content-type"] = "application/json";
  axios.defaults.headers.accept = "application/json";
  // axios.defaults.headers.accept = "*/*";
  axios.defaults.headers["Accept-Language"] = localStorage.getItem("userLang")
    ? localStorage.getItem("userLang")
    : "ar";

  // Permissions
  const userPermissions = store.getters.userPermissions
    ? store.getters.userPermissions
    : JSON.parse(localStorage.getItem("userPermissions"));
  let permissionsName = userPermissions.map(function (element) {
    return `${element.name}`;
  });
  appInstance.directive("can", {
    mounted: function (el, binding, vnode) {
      if (!permissionsName.includes(binding.value)) {
        vnode.el.parentElement.removeChild(vnode.el);
      }
    },
  });
}

// Check User Is Authorized
if (window.location.pathname != "/login") {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("userData");
        localStorage.removeItem("userPermissions");
        return router.push("/login");
      }
      return Promise.reject(error.response);
    }
  );
}
