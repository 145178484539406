import { createRouter, createWebHistory } from "vue-router";

import DashboardLayout from "@/layout/DashboardLayout";
import AuthLayout from "@/layout/AuthLayout";

// import Dashboard from "../views/Dashboard.vue";
// Will be deleted
import Icons from "../views/Icons.vue";
import Maps from "../views/Maps.vue";
import Profile from "../views/UserProfile.vue";
import Tables from "../views/Tables.vue";

import Login from "../views/Login.vue";
import Register from "../views/Register.vue";

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    meta: {
      requiresAuth: true,
    },
    component: DashboardLayout,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("../views/Dashboard.vue"),
        // components: { default: Dashboard },
      },
      {
        path: "/dashboard2",
        name: "dashboard2",
        component: () => import("../views/dash.vue"),
        meta: {
          roles: ["admin", "manager", "publisher", "reviewer", "editor"],
        },
      },
      // Start Admin Routes
      // {
      //   path: "/settings",
      //   name: "settings",
      //   component: () => import("../views/Settings/Form.vue"),
      //   meta: {
      //     roles: ["admin"],
      //   },
      // },
      // Start Domains
      {
        path: "/domains",
        name: "domains",
        component: () => import("../views/Domains/Domains.vue"),
        meta: {
          roles: ["admin", "social_media_specialist"],
        },
      },
      {
        path: "/domain-form/:id?",
        name: "domain-form",
        component: () => import("../views/Domains/Form.vue"),
        meta: {
          roles: ["admin"],
        },
      },
      {
        path: "/domain-setting/:id",
        name: "domain-setting",
        component: () => import("../views/Domains/FormSetting.vue"),
        meta: {
          roles: ["admin"],
        },
      },
      {
        path: "/domain-ads/:id",
        name: "domain-ads",
        component: () => import("../views/Domains/FormAds.vue"),
        meta: {
          roles: ["admin", "social_media_specialist"],
        },
      },
      {
        path: "/domain-seo/:id",
        name: "domain-seo",
        component: () => import("../views/Domains/FormSeo.vue"),
        meta: {
          roles: ["admin", "social_media_specialist"],
        },
      },
      // End Domains
      // Start Departments
      {
        path: "/departments",
        name: "departments",
        component: () => import("../views/Departments/Departments.vue"),
        meta: {
          roles: ["admin"],
        },
      },
      {
        path: "/department-form/:id?",
        name: "department-form",
        component: () => import("../views/Departments/Form.vue"),
        meta: {
          roles: ["admin"],
        },
      },
      {
        path: "/all-departments",
        name: "all-departments",
        component: () => import("../views/Departments/DepartmentsDomains.vue"),
        meta: {
          roles: ["admin", "social_media_specialist"],
        },
      },
      {
        path: "/department-ads/:id",
        name: "department-ads",
        component: () => import("../views/Departments/FormAds.vue"),
        meta: {
          roles: ["admin", "social_media_specialist"],
        },
      },
      {
        path: "/department-seo/:id",
        name: "department-seo",
        component: () => import("../views/Departments/FormSeo.vue"),
        meta: {
          roles: ["admin", "social_media_specialist"],
        },
      },
      // End Departments
      // Start Languages
      {
        path: "/languages",
        name: "languages",
        component: () => import("../views/Languages/Languages.vue"),
        meta: {
          roles: ["admin"],
        },
      },
      {
        path: "/language-form/:id?",
        name: "language-form",
        component: () => import("../views/Languages/Form.vue"),
        meta: {
          roles: ["admin"],
        },
      },
      // End Languages
      // Start Users
      {
        path: "/users",
        name: "users",
        component: () => import("../views/Users/Users.vue"),
        meta: {
          roles: ["admin", "manager", "projects_manager"],
        },
      },
      {
        path: "/user-form/:id?",
        name: "user-form",
        component: () => import("../views/Users/Form.vue"),
        meta: {
          roles: ["admin", "projects_manager"],
        },
      },
      {
        path: "/update-profile",
        name: "update-profile",
        component: () => import("../views/Users/UpdateProfile.vue"),
        meta: {
          roles: [
            "admin",
            "projects_manager",
            "business_developer",
            "social_media_specialist",
            "manager",
            "publisher",
            "reviewer",
            "editor",
          ],
        },
      },
      // End Users
      {
        path: "/newsletter",
        name: "newsletter",
        component: () => import("../views/Newsletters/Newsletters.vue"),
        meta: {
          roles: ["admin", "social_media_specialist", "test"],
        },
      },
      // Start Ads
      {
        path: "/ads",
        name: "ads",
        component: () => import("../views/Ads/Ads.vue"),
        meta: {
          roles: ["admin", "social_media_specialist"],
        },
      },
      {
        path: "/ad-form/:id?",
        name: "ad-form",
        component: () => import("../views/Ads/Form.vue"),
        meta: {
          roles: ["admin", "social_media_specialist"],
        },
      },
      // End Ads
      {
        path: "/contacts",
        name: "contacts",
        component: () => import("../views/Contact/Contact.vue"),
        meta: {
          roles: ["admin", "projects_manager"],
        },
      },
      {
        path: "/careers",
        name: "careers",
        component: () => import("../views/Careers/Careers.vue"),
        meta: {
          roles: ["admin", "projects_manager"],
        },
      },
      {
        path: "/career-form/:id?",
        name: "career-form",
        component: () => import("../views/Careers/Form.vue"),
        meta: {
          roles: ["admin", "projects_manager"],
        },
      },
      {
        path: "/career-cvs/:id?",
        name: "career-cvs",
        component: () => import("../views/Careers/Cvs.vue"),
        meta: {
          roles: ["admin", "projects_manager"],
        },
      },
      {
        path: "/career-cv/:career_id/:cv_id",
        name: "career-cv",
        component: () => import("../views/Careers/Cv.vue"),
        meta: {
          roles: ["admin", "projects_manager"],
        },
      },
      {
        path: "/quizzes",
        name: "quizzes",
        component: () => import("../views/Quizzes/Quizzes.vue"),
        meta: {
          roles: ["admin", "projects_manager"],
        },
      },
      {
        path: "/quiz-form/:id?",
        name: "quiz-form",
        component: () => import("../views/Quizzes/Form.vue"),
        meta: {
          roles: ["admin", "projects_manager"],
        },
      },
      {
        path: "/quiz-answers/:domain/:id/:quiz_hash_id",
        name: "quiz-answers",
        component: () => import("../views/Quizzes/Answers.vue"),
        meta: {
          roles: ["admin", "projects_manager"],
        },
      },
      // End Admin Routes
      // Start Articles Routes
      {
        path: "/articles/:status?",
        name: "articles",
        component: () => import("../views/Articles/Articles.vue"),
        meta: {
          roles: [
            "admin",
            "social_media_specialist",
            "manager",
            "publisher",
            "reviewer",
            "editor",
          ],
        },
      },
      {
        path: "/article-form/:id?",
        name: "article-form",
        component: () => import("../views/Articles/Form.vue"),
        meta: {
          roles: ["manager", "editor", "publisher", "reviewer"],
        },
      },
      // {
      //   path: "/article-ads/:id",
      //   name: "article-ads",
      //   component: () => import("../views/Articles/FormAds.vue"),
      //   meta: {
      //     roles: ["admin", "social_media_specialist"],
      //   },
      // },
      {
        path: "/article-seo/:id",
        name: "article-seo",
        component: () => import("../views/Articles/FormSeo.vue"),
        meta: {
          roles: ["admin", "manager", "publisher"],
        },
      },
      {
        path: "/reports/",
        name: "reports",
        component: () => import("../views/Reports/Form.vue"),
        meta: {
          roles: ["admin", "projects_manager", "business_developer", "manager"],
        },
      },
      {
        path: "/tags",
        name: "tags",
        component: () => import("../views/Tags/Tags.vue"),
        meta: {
          roles: ["manager", "publisher", "reviewer", "editor"],
        },
      },
      {
        path: "/tag-form/:id?",
        name: "tag-form",
        component: () => import("../views/Tags/Form.vue"),
        meta: {
          roles: ["manager", "publisher", "reviewer"],
        },
      },
      {
        path: "/keywords",
        name: "keywords",
        component: () => import("../views/Keywords/Keywords.vue"),
        meta: {
          roles: ["manager", "publisher", "reviewer", "editor"],
        },
      },
      {
        path: "/keyword-form/:id?",
        name: "keyword-form",
        component: () => import("../views/Keywords/Form.vue"),
        meta: {
          roles: ["manager", "publisher", "reviewer"],
        },
      },
      // End Articles Routes
      // Start Cross Media
      {
        path: "/cross-media",
        name: "cross medial",
        component: () => import("../views/CrossMedia/CrossMedia.vue"),
        meta: {
          roles: ["manager", "publisher", "reviewer"],
        },
      },
      {
        path: "/cross-media-form/:id?",
        name: "cross-media-form",
        component: () => import("../views/CrossMedia/Form.vue"),
        meta: {
          roles: ["manager", "publisher", "reviewer", "editor"],
        },
      },
      // End Cross Media
      {
        path: "/icons",
        name: "icons",
        components: { default: Icons },
      },
      {
        path: "/maps",
        name: "maps",
        components: { default: Maps },
      },
      {
        path: "/profile",
        name: "profile",
        components: { default: Profile },
      },
      {
        path: "/tables",
        name: "tables",
        components: { default: Tables },
      },
    ],
  },
  {
    path: "/",
    redirect: "login",
    name: "Login",
    component: AuthLayout,
    meta: {
      hideForAuth: true,
    },
    children: [
      {
        path: "/login",
        name: "login",
        components: { default: Login },
      },
      {
        path: "/register",
        name: "register",
        components: { default: Register },
      },
    ],
  },
];

const router = createRouter({
  linkActiveClass: "active",
  history: createWebHistory(process.env.BASE_URL),
  // mode: "history",
  routes,
});

// Check if Authorized before routes
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("token")) {
      if (to.name != "dashboard") {
        var user_role = JSON.parse(localStorage.getItem("userData"))["role"]
          .name;
        var meta_roles = to.meta.roles ?? [];
        if (meta_roles.includes(user_role)) next();
        else {
          next({ name: "dashboard" });
        }
      } else {
        next();
      }
      // next();
    } else {
      next({ name: "Login" });
    }
  }
  if (to.matched.some((record) => record.meta.hideForAuth)) {
    if (localStorage.getItem("token")) {
      next({ path: "/" });
    } else {
      next();
    }
  }
});

// Mapping Permissions
// let permissions = JSON.parse(localStorage.getItem("userPermissions"));
// let permissionName = permissions.map(function (element) {
//   return `${element.name}`;
// });

// console.log(JSON.parse(localStorage.getItem("userData"))["role_name"]);
// console.log(localStorage.getItem("userPermissions"));
// hasAccess(routeName){
//   permissions = localStorage.getItem("permissions")
//   console.log(permissions)

//   switch (routeName) {

//     case "home":
//       return true;

//     case "users":
//       return permissions.includes("View All Users");

//     case "permissions":
//       return permissions.includes("View All Permissions");

//     default:
//       return false;
//   }
// }
export default router;
