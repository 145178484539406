<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-center text-muted mb-4">
            <small>{{ $t("login.sign in with credentials") }}</small>
          </div>
          <form @submit.prevent="login" class="login-form">
            <div
              class="bg-danger text-white px-2 my-2 py-1 live-error text-center"
              v-if="isError"
            >
              {{ isError }}
            </div>
            <base-input
              formClasses="input-group-alternative mb-3"
              :placeholder="$t('form.email')"
              addon-left-icon="ni ni-email-83"
              v-model="email"
            >
            </base-input>
            <div
              v-show="emailValidationErr"
              class="live-error bg-danger text-white mb-2 p-1"
            >
              {{ $t("validation.email not valid") }}
            </div>

            <base-input
              formClasses="input-group-alternative mb-3"
              :placeholder="$t('form.password')"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="password"
            >
            </base-input>
            <div
              v-show="passwordValidationErr"
              class="live-error bg-danger text-white mb-2 p-1"
            >
              {{ $t("validation.password min") }}
            </div>
            <div class="text-center">
              <button
                class="my-4 btn btn-primary"
                :disabled="!isValidForm || isLoading"
              >
                {{ $t("login.sign in") }} <span v-if="isLoading">...</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "login",
  data() {
    return {
      email: "",
      password: "",
      error: false,
      success: false,
    };
  },
  computed: {
    emailValidationErr() {
      return (
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          this.email
        ) && this.email.length > 0
      );
    },
    passwordValidationErr() {
      return this.password.length > 0 && this.password.length < 6;
    },
    isValidForm() {
      return (
        this.password.length > 5 &&
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          this.email
        ) &&
        this.email.length > 0
      );
    },
    isLoading() {
      return this.$store.getters.isLoading;
    },
    isError() {
      return this.$store.getters.isError;
    },
  },
  methods: {
    login() {
      let { email, password } = this;
      this.$store.dispatch("Login", { email, password }).then(() => {
        // this.error = this.$store.getters.isError;
        // this.$router.push(path);
        // this.$store.dispatch("LoginData");
      });
    },
  },
};
</script>
<style></style>
